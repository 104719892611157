import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useSnackbar } from 'notistack';
import UserStstus from '../components/UserStatus'
import Loading from '../components/Loading';

function InSignUp() {
  const token = localStorage.getItem('token');
  const [userInfo, setUserInfo] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const { enqueueSnackbar } = useSnackbar();

  // 请求数据
  React.useEffect(() => {
    const getUserInfo = async () => {
      const csrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
      const response = await fetch(`/api/user/info/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        body: JSON.stringify({ token })
      });

      const userInfo = await response.json();

      if (response.ok) {
        setUserInfo(userInfo)
      }
      setIsLoading(false);
    }
    getUserInfo()
  }, [token])

  // 提交数据
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get('username');
    const password = data.get('password');
    const confirm_password = data.get('confirm_password')
    const last_name = data.get('last_name')
    const first_name = data.get('first_name')
    const email = data.get('email')

    if (password !== confirm_password) {
      enqueueSnackbar('两次输入密码不一致', { variant: 'error' });
      return;
    }
    const csrfToken = document.cookie
      .split('; ')
      .find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
    const response = await fetch(`/api/user/info/change/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrfToken,
      },
      body: JSON.stringify({ token, username, password, last_name, first_name, email })
    });

    const signUpInfo = await response.json();

    if (response.ok) {
      enqueueSnackbar(signUpInfo.message, { variant: 'success' });
    } else {
      enqueueSnackbar(signUpInfo.message, { variant: 'error' });
    }
  };

  // 加载中
  if (isLoading) {
    return (<Loading />);
  }

  return (
    <Box sx={{ pt: 8 }}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            我 的
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled
                  fullWidth
                  id="username"
                  label="用户名"
                  name="username"
                  autoComplete="username"
                  defaultValue={userInfo.username}
                />
              </Grid>
              <Grid item xs={12}><Divider>个人信息</Divider></Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="last_name"
                  label="姓氏"
                  name="last_name"
                  autoComplete="family-name"
                  defaultValue={userInfo.last_name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="first_name"
                  label="名字"
                  name="first_name"
                  autoComplete="given-name"
                  defaultValue={userInfo.first_name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  label="邮箱地址"
                  name="email"
                  autoComplete="email"
                  defaultValue={userInfo.email}
                />
              </Grid>
              <Grid item xs={12}><Divider>修改密码</Divider></Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="password"
                  label="设置新密码"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  helperText='如需修改密码请填写新密码 否则留空'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="confirm_password"
                  label="确认新密码"
                  type="Password"
                  id="confirm_password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}><Divider>登录记录</Divider></Grid>
              <Grid item xs={12}>
                <TextField
                  disabled
                  fullWidth
                  id="last_login"
                  label="上次登录"
                  name="last_login"
                  defaultValue={userInfo.last_login}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled
                  fullWidth
                  id="date_joined"
                  label="创建日期"
                  name="date_joined"
                  defaultValue={userInfo.date_joined}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              color='inherit'
              sx={{ mt: 3, mb: 2 }}
            >
              保存更改
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default function SignUp() {
  return (
    <UserStstus>
      <InSignUp />
    </UserStstus>
  );
}
