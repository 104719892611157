import { Navigate } from 'react-router-dom'
import About from './pages/About'
import Home from './pages/Home'
import Heli from './pages/Heli'
import Lida from './pages/Lida'
import Line from './pages/Line'
import Alarm from './pages/Alarm'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import Me from './pages/Me'
import Announcements from './pages/Announcements'

const router = [
  {
    path: '/announcements',
    element: <Announcements />
  },
  {
    path: '/signup',
    element: <SignUp />
  },
  {
    path: '/signin',
    element: <SignIn />
  },
  {
    path: '/me',
    element: <Me />
  },
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/about',
    element: <About />
  },
  {
    path: '/heli',
    element: <Heli />
  },
  {
    path: '/lida',
    element: <Lida />
  },
  {
    path: '/line',
    element: <Line />
  },
  {
    path: '/alarm',
    element: <Alarm />
  },
  {
    path: '/',
    element: <Navigate to='/home' />
  }
]

export default router